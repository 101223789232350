import React from "react"

import Layout from "../../components/layout"
import Seo from "../../components/seo"
import styled from "styled-components"

const StyledWrapper = styled.div`
  margin: 2rem 5vw;
`

export default function Page() {
  return (
    <Layout location="CH">
      <Seo
        title="Nutzungsbedingungen DoryGo "
        description="Hier finden Sie die Nutzungsbedingunge DoryGo App"
      />
      <StyledWrapper>
        <h1>
          Allgemeine Gesch&auml;ftsbedingungen der DoryHealth AG (nachfolgend
          &bdquo;AGB&ldquo;)
        </h1>
        <p><b>Version vom 15.05.2021</b></p>
        <ol>
          <li>
            <strong>
              Geltung dieser Allgemeinen Gesch&auml;ftsbedingungen
            </strong>
          </li>
        </ol>
        <p>
          Die DoryHealth AG, Wrightstrasse 58, 8152 Opfikon, Schweiz,
          eingetragen im Handelsregister des Kantons Z&uuml;rich unter der
          Nummer CHE-220.969.862 (nachfolgend &bdquo;
          <strong>DoryHealth AG</strong>&ldquo;) betreibt die DoryGo App und
          ein Patientenverwaltungssystem. Diese AGB, der Kooperationsvertrag und
          die Datenschutzerkl&auml;rung der DoryHealth AG regeln das
          Vertragsverh&auml;ltnis zwischen der DoryHealth AG und den Apotheken
          betreffend die Nutzung der DoryGo App und des
          Patientenverwaltungs-systems abschlie&szlig;end. Mit der Benutzung des
          Patientenverwaltungssystem gelten diese AGB und die
          Datenschutzerkl&auml;rung der DoryHealth AG als akzeptiert. Wenn die
          Apotheke diese AGB oder die Datenschutzerkl&auml;rung der DoryHealth
          AG nicht akzeptieren m&ouml;chte, ist eine Nutzung des
          Patientenverwaltungssystems zu unterlassen.
        </p>
        <p>
          Die DoryHealth AG beh&auml;lt sich das Recht vor, diese AGB, die
          Datenschutzerkl&auml;rung, die DoryGo App und das
          Patientenverwaltungssystem jederzeit zu &auml;ndern. Bereits
          registrierte Apotheken werden vorg&auml;ngig &uuml;ber &Auml;nderungen
          notifiziert und m&uuml;ssen innert der in der
          &Auml;nderungsnotifikation angegeben Frist ihren Vertrag &uuml;ber die
          Nutzung der DoryGo App und des Patientenverwaltungssytems per dem
          n&auml;chstm&ouml;glichen K&uuml;ndigungstermin k&uuml;ndigen, sofern
          sie mit den &Auml;nderungen nicht einverstanden sind, andernfalls die
          &Auml;nderungen innert der angegebenen Frist verbindlich werden und
          als von der Apotheke akzeptiert gelten. &nbsp;
        </p>
        <p>
          Allf&auml;llige allgemeine Gesch&auml;ftsbedingungen der Apotheke
          haben keine Geltung f&uuml;r das Vertragsverh&auml;ltnis zwischen der
          DoryHealth AG und den Apotheken, au&szlig;er betreffend das
          Rechtsverh&auml;ltnis zwischen Personen, welche &uuml;ber die
          DoryGo App bei der Apotheke Medikamente bestellen (nachfolgend
          &bdquo;<strong>Nutzer</strong>&ldquo;), und der Apotheke betreffend
          Medikamentenbestellungen.
        </p>
        <ol start="2">
          <li>
            <strong>Beschrieb </strong>
            <strong>des Angebotes der DoryHealth AG</strong>
          </li>
        </ol>
        <p>
          Die von der DoryHealth AG angebotene DoryGo App ist eine App, (a)
          &uuml;ber welche Apotheken Nutzer vermittelt werden, wobei Nutzer aus
          den vorgeschlagenen Apotheken frei w&auml;hlen k&ouml;nnen, (b)
          &uuml;ber welche Nutzer im Rahmen eines Abonnements
          regelm&auml;&szlig;ig Medikamente von der Apotheke vorsortiert in
          Medikamentenblistern der DoryHealth AG geliefert erhalten k&ouml;nnen,
          (c) &uuml;ber welche Nutzer einen Medikamentenplan sowie
          Medikamenteneinnahme-Erinnerungsmeldungen generieren k&ouml;nnen, und
          (d) &uuml;ber welche sie mit der gew&auml;hlten Apotheke kommunizieren
          k&ouml;nnen (nachfolgend &bdquo;<strong>DoryGo App</strong>
          &ldquo;). &Uuml;ber die DoryGo App der Apotheke &uuml;bermittelte
          Medikamenten-Bestellungen von Nutzern gelten als Offerte an die
          Apotheke zum Medikamentenkauf.
        </p>
        <p>
          Das von der DoryHealth AG angebotene Patientenverwaltungssystem
          erlaubt der Apotheke die Verwaltung und Dokumentation der von Nutzern
          und deren &Auml;rzten zur Verf&uuml;gung gestellten Informationen
          (z.B. Gesundheitszustand, verschriebene und eingenommene Medikamente,
          Kontaktdaten), sowie der von Nutzern bestellten und eingenommenen
          Medikamente. Sodann kann die Apotheke damit Medikamentenpl&auml;ne
          f&uuml;r die Nutzer erstellen und verwalten, sowie den
          Medikamentenvorrat &uuml;berwachen (nachfolgend &bdquo;
          <strong>Patientenverwaltungssystem</strong>&ldquo;).
        </p>
        <p>
          Die DoryHealth AG ist nicht Vertragspartei betreffend die
          Medikamenten-Lieferung und -Sortierung, sondern agiert nur als
          Vermittler der Apotheken, IT-Dienstleister sowie Lieferant der leeren
          Medikamentenboxen und Medikamentenblistern. Eine &Uuml;berpr&uuml;fung
          der einzunehmenden Medikamente und Medikamentenpl&auml;ne, Fragen und
          allf&auml;llige Anpassungen betreffend die Medikamenten-Lieferungen
          muss direkt zwischen dem Nutzer und der Apotheke erfolgen. Die
          DoryGo App bietet weder medizinische Beratungen, Empfehlungen noch
          Diagnose- oder Therapieinformationen irgendwelcher Art an.
        </p>
        <p>
          Die DoryHealth AG f&uuml;hrt keine Kontrolle der Richtigkeit,
          Vollst&auml;ndigkeit und Aktualit&auml;t der durch den Nutzer
          eingegebenen Informationen durch.
        </p>
        <ol start="3">
          <li>
            <strong>Lizenz</strong>
          </li>
        </ol>
        <p>
          Die Apotheke erh&auml;lt ein nicht-exklusives, nicht
          &uuml;bertragbares und nicht unterlizenzierbares Recht zur Nutzung des
          Patientenverwaltungssystems sowie den darauf ver&ouml;ffentlichten
          Inhalten und Daten zu den unter Ziffer 2 dieser AGB beschriebenen
          Zwecken im Rahmen dieser AGB ein. Jegliche andere Verwendung ist
          untersagt.
        </p>
        <p>
          Der Apotheke ist es untersagt, Dritten Zugang zum
          Patientenverwaltungssystem einzur&auml;umen, dieses zu kopieren, zu
          &auml;ndern, zu manipulieren oder zu entschl&uuml;sseln.
        </p>
        <ol start="4">
          <li>
            <strong>Pflichten der Apotheke</strong>
          </li>
        </ol>
        <p>
          Es gelten die Pflichten gem&auml;&szlig; dem separaten
          Kooperationsvertrages.
        </p>
        <ol start="5">
          <li>
            <strong>Pflichten und Rechte von DoryHealth AG</strong>
          </li>
        </ol>
        <p>
          Die DoryHealth AG stellt die DoryGo App und das
          Patientenverwaltungssystem mit den unter Ziffer 2 dieser AGB
          beschriebenen Funktionen zur Verf&uuml;gung. Die DoryHealth AG kann
          die DoryGo App und die Patientenverwaltungssystem nach eigenem
          Ermessen jederzeit aktualisieren, und zus&auml;tzliche oder angepasste
          Funktionen zur Verf&uuml;gung stellen. Die Apotheke ist nur zur
          Nutzung der jeweils aktuellen Version der DoryGo App und des
          Patientenverwaltungssystems berechtigt.
        </p>
        <p>
          Die DoryHealth AG ist berechtigt, die Leistungen nicht zu erbringen
          oder zu unterbrechen sofern:
        </p>
        <p>
          (a) Die Apotheke mehr als 60 Tage mit der vereinbarten
          Entsch&auml;digung in Verzug ist;
        </p>
        <p>
          (b) &uuml;ber die Apotheke ein Konkurs- oder Nachlassverfahren
          er&ouml;ffnet wird;
        </p>
        <p>
          (c) nach Ermessen der DoryHealth AG Anhaltspunkte daf&uuml;r
          vorliegen, dass die Apotheke diese AGB verletzt;
        </p>
        <p>(d) der Vertrag mit der Apotheke abgelaufen ist.</p>
        <p>
          Zus&auml;tzlich gelten die Pflichten gem&auml;&szlig; dem
          Kooperationsvertrages
        </p>
        <ol start="6">
          <li>
            <strong>Geistiges Eigentum</strong>
          </li>
        </ol>
        <p>
          Die DoryGo App und das Patientenverwaltungssystem, deren Software,
          Texte, Bilder, Graphiken und Marken sind geistiges Eigentum der
          DoryHealth AG oder deren Vertragspartner, gesch&uuml;tzt durch
          Urheberrecht, Markenrecht und andere Gesetze.
        </p>
        <ol start="7">
          <li>
            <strong>
              Gew&auml;hrleistungs- und Haftungsausschluss, Freistellung
            </strong>
          </li>
        </ol>
        <p>
          Bei der DoryGo App und dem Patientenverwaltungssystem handelt es
          sich um neu entwickelte IT- L&ouml;sungen &bdquo;as-is&ldquo; ohne
          jegliche Gew&auml;hrleistung. Die DoryGo App und das
          Patientenverwaltungssystem sind Hilfsmittel und deren Nutzung erfolgt
          auf eigenes Risiko.
        </p>
        <p>
          Der Kaufvertrag &uuml;ber die bestellten Medikamente erfolgt direkt
          zwischen Apotheke und Nutzer, DoryHealth AG ist nur Vermittler der
          Apotheken, IT-Dienstleister sowie Lieferant der leeren
          Medikamentenboxen und Medikamentenblistern. DoryHealth AG kontrolliert
          die Berechtigung zur Bestellung rezeptpflichtiger Medikamente, die
          Indikation, die Wirksamkeit und Risiken der bestellten Medikamente
          nicht. DoryHealth AG kann keine Verantwortung f&uuml;r die korrekte
          Bestellung durch den Nutzer &uuml;bernehmen. Falls der Nutzer die
          bestellten Medikamente nicht bezahlt, m&uuml;ssen allf&auml;llige
          Anspr&uuml;che direkt gegen&uuml;ber dem Nutzer gestellt werden.
          DoryHealth AG ist f&uuml;r Anspr&uuml;che zwischen den Apotheken und
          Nutzern nicht haftbar. Die Apotheke tr&auml;gt allein die
          Verantwortung f&uuml;r den Medikamentenverkauf und die Eintreibung der
          Zahlung f&uuml;r die bestellten Medikamente. Die Apotheke h&auml;lt
          DoryHealth AG von jeglichen Anspr&uuml;chen und Kosten
          (einschlie&szlig;lich Anwaltskosten) schadlos, welche von Nutzern oder
          Dritten im Zusammenhang mit der Medikamenten-Bestellung gegen&uuml;ber
          der DoryHealth AG gestellt werden.
        </p>
        <p>
          Obwohl die DoryHealth AG einen ununterbrochenen Betrieb der DoryHealth
          App anstrebt, haftet die DoryHealth AG nicht f&uuml;r eine zeitweilige
          Nichtverf&uuml;gbarkeit der DoryGo App,
          &Uuml;bermittlungsverz&ouml;gerungen oder Fehlfunktionen. Sodann kann
          die DoryHealth AG nicht gew&auml;hrleisten, dass die DoryGo App
          mit der Software oder Hardware der Apotheke kompatibel ist und
          funktioniert. Die Nutzung ist nicht f&uuml;r Notfallbestellungen
          geeignet. Die Haftung von DoryHealth AG f&uuml;r Sch&auml;den,
          vergeblichen Aufwand und entgangenen Gewinn wird hiermit
          ausgeschlossen.
        </p>
        <p>
          Trotz dem Einsatz von angemessenen Sicherheitsma&szlig;nahmen zum
          Schutz der vom Nutzer eingegebenen personenbezogenen Daten, wie zum Beispiel einer Verschlüsselung, ist die
          &Uuml;bertragung von Daten &uuml;ber das Internet leider nie
          vollkommen sicher. Deshalb kann DoryHealth AG keine Garantie f&uuml;r
          die Sicherheit der Daten &uuml;bernehmen. Jede &Uuml;bermittlung von
          Daten &uuml;ber die DoryGo App und das Patientenverwaltungssystem
          erfolgt auf eigene Gefahr.
        </p>
        <p>
          Die Haftung von DoryHealth AG wird im gesetzlich zul&auml;ssigen
          Umfang ausgeschlossen. Dieser Haftungsausschluss gilt auch f&uuml;r
          die Besch&auml;ftigten, Vertreter und Erf&uuml;llungsgehilfen von
          DoryHealth.
        </p>
        <ol start="8">
          <li>
            <strong>Salvatorische Klausel</strong>
          </li>
        </ol>
        <p>
          Sollten einzelne Bestimmungen dieser AGB unwirksam sein oder werden,
          so behalten die &uuml;brigen Bestimmungen dieser AGB
          uneingeschr&auml;nkte G&uuml;ltigkeit. Die unwirksame Bestimmung gilt
          durch eine rechtswirksame Bestimmung ersetzt, welche dem
          wirtschaftlichen Sinn und Zwecke der unwirksamen Bestimmung am
          n&auml;chsten kommt. Die vorstehende Regelung gilt entsprechend bei
          Regelungsl&uuml;cken.
        </p>
        <ol start="9">
          <li>
            <strong>Anwendbares Recht und Gerichtsstand</strong>
          </li>
        </ol>
        <p>
          Der Vertrag zwischen der DoryHealth AG und der Apotheke untersteht
          materiellem Schweizerischem Recht, unter Ausschluss der
          Kollisionsregeln des Internationalen Privatrechts und internationaler
          Vertr&auml;ge.
        </p>
        <p>
          F&uuml;r Streitigkeiten sind ausschlie&szlig;lichen die ordentlichen
          Gerichte am Sitz der DoryHealth AG zust&auml;ndig.
        </p>
      </StyledWrapper>
    </Layout>
  )
}
